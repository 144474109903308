import {LogoCardContent} from '../components/utils/LogoCard';

export const projects: LogoCardContent[] = [
    {
        key: 'add-compliance',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/addcompliance/',
        ext: 'jpg'
    },
    {
        key: 'cloud-cycle',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/cloudcycle/',
        ext: 'png'
    },
    {
        key: 'distopt',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/distopt/',
        ext: 'png'
    },
    {
        key: 'ic4f',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/ic4f/',
        ext: 'png'
    },
    {
        key: 'migrate',
        website: 'https://www.migrate-it2green.de/',
        ext: 'png'
    },
    {
        key: 'planqk',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/planqk',
        ext: 'svg',
    },
    {
        key: 'radon',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/radon/',
        ext: 'png'
    },
	{
        key: 'resus',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/resus/',
        ext: 'png'
    },
    {
        key: 'sepiapro',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/sepia.pro/',
        ext: 'jpg'
    },
    {
        key: 'simtech',
        website: 'https://www.iaas.uni-stuttgart.de/forschung/projekte/simtech/',
        ext: 'png',
    },
    {
        key: 'smart-orchestra',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/smartorchestra/',
        ext: 'png'
    },
    {
        key: 'smart-service-welt',
        website: 'https://www.bmwi.de/Redaktion/DE/Dossier/digitalisierung.html',
        ext: 'jpeg'
    },
    {
        key: 'sofdcar',
        website: 'https://sofdcar.de/language/en',
        ext: 'png'
    },
    {
        key: 'sustain-life',
        website: 'https://www.iaas.uni-stuttgart.de/en/projects/sustainlife/',
        ext: 'png'
    },
    {
        key: 'trusted-cloud',
        website: 'https://www.trusted-cloud.de/',
        ext: 'jpg'
    }
]
