import React from 'react'
import {Box, Container, Stack, Typography} from '@mui/material'
import {GitHub} from '@mui/icons-material';
import ExternalLink from '../utils/ExternalLink';
import {theme} from '../../utils/theme';
import ExternalButton from '../utils/ExternalButton';


export default function OpenSource() {

    return (
        <Box className="bg-dotted">
            <Container maxWidth="sm" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4} sx={{textAlign: 'center'}}>
                    <ExternalLink href="https://github.com/OpenTOSCA" color={theme.palette.text.primary}>
                        <GitHub sx={{fontSize: 150}}/>
                    </ExternalLink>

                    <Typography variant="h4" align="center">
                        Open Source
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        OpenTOSCA is open source, available on GitHub. Become part of the community
                        and help us developing and reaching our goals.
                    </Typography>

                    <Stack direction="row" spacing={2} justifyContent="center">
                        <ExternalButton
                            href="https://github.com/OpenTOSCA"
                            text="Join us"
                        />

                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}
