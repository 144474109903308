import React from 'react'
import {Box, Divider} from '@mui/material'
import routes from '../utils/routes'
import RouteContainer from './utils/RouteContainer'
import AboutUs from './home/AboutUs';
import Publications from './home/Publications';
import Projects from './home/Projects';
import OpenSource from './home/OpenSource';
import OpenTOSCA from './home/OpenTOSCA';
import EclipseWinery from './home/EclipseWinery';
import Features from './home/Features';
import Docker from './home/Docker';
import Resources from './home/Resources';
import OpenTOSCAContainer from './home/OpenTOSCAContainer';
import OpenTOSCAVintner from './home/OpenTOSCAVintner';


export default function Home() {
    return (
        <Box>
            <RouteContainer path={routes.home.path}>
                <OpenTOSCA/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.winery.path}>
                <EclipseWinery/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.container.path}>
                <OpenTOSCAContainer/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.vintner.path}>
                <OpenTOSCAVintner/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.features.path}>
                <Features/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.docker.path}>
                <Docker/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.resources.path}>
                <Resources/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.openSource.path}>
                <OpenSource/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.projects.path}>
                <Projects/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.publications.path}>
                <Publications/>
            </RouteContainer>

            <Divider/>

            <RouteContainer path={routes.aboutUs.path}>
                <AboutUs/>
            </RouteContainer>
        </Box>
    )
}
