import {Link} from '@mui/material'
import React from 'react'

export default function ExternalLink({
                                         href,
                                         text,
                                         children,
                                         color = 'primary'
                                     }: { href: string; text?: string; children?: React.ReactNode, color?: string }) {
    return (
        <Link href={href}
              target="_blank"
              rel="noreferrer noopener"
              color={color}
        >{text}{children}</Link>
    )
}
