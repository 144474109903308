import React from 'react'
import {Box, Container, Stack, Typography} from '@mui/material'
import ExternalButton from '../utils/ExternalButton';


export default function OpenTOSCA() {

    return (
        <Box>
            <Container maxWidth="lg" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>

                    <Box sx={{textAlign: 'center'}}>
                        <img
                            alt="OpenTOSCA"
                            width="200"
                            src={
                                process.env.PUBLIC_URL + '/img/opentosca/logo.svg'
                            }
                        />
                    </Box>

                    <Box>
                        <Typography variant="h4" align="center" gutterBottom>
                            OpenTOSCA
                        </Typography>

                        <Typography variant="h5" align="center">
                            End-to-End Toolchain for Your Cloud Applications
                        </Typography>
                    </Box>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        The OpenTOSCA Ecosystem is an Open-Source TOSCA Runtime Environment to deploy and manage Cloud
                        applications. It enables the automated provisioning of applications that are modeled
                        using TOSCA and packaged as CSARs.
                    </Typography>

                    <Stack direction="row" spacing={2} justifyContent="center">
                        <ExternalButton
                            href="https://winery.readthedocs.io/en/latest/user/getting-started.html"
                            text="Getting Started"/>
                    </Stack>

                </Stack>
            </Container>
        </Box>
    )
}
