import React from 'react'
import {Box, Container, Stack, Typography} from '@mui/material'
import {theme} from '../../utils/theme';
import ExternalButton from '../utils/ExternalButton';


export default function OpenTOSCAContainer() {

    return (
        <Box sx={{bgcolor: theme.palette.grey[100]}}>
            <Container maxWidth="lg" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>
                    <Typography variant="h4" align="center">
                        OpenTOSCA Container
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        The OpenTOSCA Container enables an automated provisioning of cloud applications by
                        analysing the TOSCA model and invoking the Build Plan to instantiate a new application
                        instance. If there is no Build Plan available, the container generates a Build Plan on
                        its
                        own. To enable the management of a certain application instance during its lifetime, the
                        OpenTOSCA Container is able to execute arbitrary Management Plans, which can be modeled
                        manually or generated for provisioning.
                    </Typography>

                    <Stack direction="row" spacing={2} justifyContent="center">
                        <ExternalButton
                            href="https://github.com/OpenTOSCA/container/"
                            text="Repository"
                        />

                        <ExternalButton
                            href="https://opentosca.github.io/container/"
                            text="Documentation"/>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}
