import React from 'react'
import {Box, Card, CardContent, Container, Grid, Stack, Typography} from '@mui/material'
import {MilitaryTech} from '@mui/icons-material';
import {features} from '../../repository/features';


export default function Features() {

    return (

        <Box className="bg-dotted">
            <Container maxWidth="xl" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>
                    <Typography align="center">
                        <MilitaryTech sx={{fontSize: 150}} color="primary"/>
                    </Typography>

                    <Typography variant="h4" align="center">
                        Features
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        OpenTOSCA supports a broad range of features.
                        <br/>
                        Some of them are listed below. Check them out.
                    </Typography>

                    <Grid container spacing={2} justifyContent="center" sx={{pt: 4}}>
                        {features.map((feature, index) => (
                            <Grid item key={index}>

                                <Card sx={{width: 300, textAlign: 'center'}} elevation={0} variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" align="center" color="text.primary" paragraph>
                                            {feature.title}
                                        </Typography>
                                        <Typography variant="body1" align="center" color="text.secondary">
                                            {feature.text}
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Container>
        </Box>
    )
}
