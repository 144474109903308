import React from 'react'
import {Alert, Box, Container, Skeleton, Stack, Typography} from '@mui/material'
import usePublications from '../../hooks/usePublications';


export default function Publications() {

    const publications = usePublications()

    return (
        <Box>
            <Container maxWidth="md" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>
                    <Typography variant="h4" align="center">
                        Publications
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        Find some of our scientific publications about the OpenTOSCA Container, the other
                        components
                        of the OpenTOSCA Ecosystem, and the TOSCA standard itself.
                    </Typography>

                    {publications.loading && <Box>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton width="60%"/>
                    </Box>}

                    {publications.data &&
                        <Typography variant="h6" color="text.secondary" paragraph component={'span'}
                                    dangerouslySetInnerHTML={{__html: publications.data}}/>
                    }

                    {publications.error &&
                        <Alert variant="filled" severity="error">Could not load publications ...</Alert>}

                </Stack>
            </Container>
        </Box>
    )
}
