import {Button} from '@mui/material'
import React from 'react'

export default function ExternalButton({href, text,}: { href: string; text: string }) {
    return (
        <Button
            href={href}
            sx={{fontSize: '0.8rem'}}
            disableElevation
            target="_blank"
            rel="noreferrer noopener"
            variant="contained">
            {text}
        </Button>)
}
