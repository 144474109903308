import React from 'react'
import {Box, Container, Link, Stack, Typography} from '@mui/material'
import ExternalLink from '../utils/ExternalLink';

export default function AboutUs() {

    const copyright = () => {
        const start = 2012
        const current = new Date().getFullYear()

        if (start === current) return start
        return `${start}-${current}`
    }

    return (
        <Box>
            <Container maxWidth="md" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>
                    <Typography variant="h4" align="center">
                        About Us
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        OpenTOSCA is developed for the purpose of research by the <ExternalLink
                            href="https://www.iaas.uni-stuttgart.de"
                            text="Institute of Architecture of Application Systems (IAAS)"
                        /> and the <ExternalLink
                            href="https://www.ipvs.uni-stuttgart.de"
                            text="Institute for Parallel and Distributed Systems (IPVS)"
                        /> of the <ExternalLink
                            href="https://www.uni-stuttgart.de"
                            text="University of Stuttgart, Germany"
                        />.
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        Copyright &copy; { copyright() } University of Stuttgart · <ExternalLink href="https://www.uni-stuttgart.de/impressum/"
                                                                       text="Impressum"/> · <Link
                        href="mailto:opentosca@iaas.uni-stuttgart.de">Contact</Link>
                    </Typography>

                </Stack>
            </Container>
        </Box>
    )
}
