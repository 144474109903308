import React from 'react'
import {Box, Container, Stack, Typography} from '@mui/material'
import {theme} from '../../utils/theme';
import ExternalLink from '../utils/ExternalLink';

export default function Resources() {
    return (<Box sx={{bgcolor: theme.palette.grey[100]}}><Container maxWidth="md" sx={{pt: 8, pb: 8}}><Stack
        spacing={4}><Typography variant="h4" align="center" gutterBottom>Resources</Typography><Typography variant="h6"
                                                                                                           align="center"
                                                                                                           color="text.secondary"
                                                                                                           paragraph>Find
        a collection of all available guides and documentations for developing and using OpenTOSCA as well as additional
        information material.</Typography><Typography variant="h6" color="text.secondary" paragraph component={'span'}>Overview
        <ul>
            <li><ExternalLink
                href="https://www.iaas.uni-stuttgart.de/publications/ART-2016-26_The-OpenTOSCA-Ecosystem-Concepts-and-Tools.pdf"
                text="The OpenTOSCA Ecosystem – Concepts & Tools"/></li>
            <li><ExternalLink href={process.env.PUBLIC_URL + '/documents/architecture.pdf'}
                              text="OpenTOSCA Architecture"/></li>
            <li><ExternalLink href={process.env.PUBLIC_URL + '/documents/presentation-opentosca.pdf'}
                              text="OpenTOSCA Presentation"/></li>
            <li><ExternalLink href={process.env.PUBLIC_URL + '/documents/presentation-tosca.pdf'}
                              text="TOSCA Presentation"/></li>
            <li><ExternalLink href="https://github.com/OpenTOSCA" text="OpenTOSCA GitHub"/></li>
        </ul>

        Installation
        <ul>
            <li><ExternalLink href="https://install.opentosca.org/"
                              text="OpenTOSCA Ecosystem: Installation Instructions"/></li>
            <li><ExternalLink href="https://github.com/OpenTOSCA/opentosca-docker" text="OpenTOSCA Docker Compose"/>
            </li>
        </ul>

        User
        <ul>
            <li><ExternalLink href="https://github.com/OpenTOSCA/opentosca-docker/blob/main/docs/tutorial-model-and-deploy.md" text="Tutorial: Model and Deploy an Application with OpenTOSCA"/></li>
            <li><ExternalLink href="https://github.com/OpenTOSCA/opentosca-docker/blob/main/docs/tutorial-node-implementation.md" text="Tutorial: Create Custom Node Types"/></li>
            <br/>
            <li><ExternalLink href="https://github.com/OpenTOSCA/tosca-definitions-common"
                              text="TOSCA Definitions Common Repository"/></li>
            <li><ExternalLink href="https://github.com/OpenTOSCA/tosca-definitions-example-applications"
                              text="TOSCA Definitions Example Applications Repository"/></li>
            <br/>
            <li><ExternalLink href="https://github.com/eclipse/winery/" text="Eclipse Winery Repository"/></li>
            <li><ExternalLink href="https://eclipse.github.io/winery/" text="Eclipse Winery Documentation"/></li>
            <li><ExternalLink href={process.env.PUBLIC_URL + '/documents/tutorial-node-types.pdf'}
                              text="How to model Node Types"/></li>
            <li><ExternalLink href={process.env.PUBLIC_URL + '/documents/tutorial-csars.pdf'}
                              text="How to build CSARs"/></li>
            <br/>
            <li><ExternalLink href="https://youtu.be/l8i91X9Usb0"
                              text="Video: Pattern-based Deployment Models and Their Automatic Execution"/></li>
            <li><ExternalLink href="https://youtu.be/w4UdWHeGpRA"
                              text="Video: Blockchain-based Collaborative Development of Application Deployment Models - Import CSAR from an unauthorized participant "/>
            </li>
        </ul>

        Development
        <ul>
            <li><ExternalLink href="https://github.com/OpenTOSCA/container/" text="OpenTOSCA Container Repository"/>
            </li>
            <li><ExternalLink href="https://opentosca.github.io/container/" text="OpenTOSCA Container Documentation"/>
            </li>
        </ul>

        Additional
        <ul>
            <li><ExternalLink href="https://docs.oasis-open.org/tosca/TOSCA/v1.0/TOSCA-v1.0.html"
                              text="Topology and Orchestration Specification for Cloud Applications Version 1.0"/></li>
            <li><ExternalLink href="https://docs.oasis-open.org/tosca/tosca-primer/v1.0/tosca-primer-v1.0.html"
                              text="Topology and Orchestration Specification for Cloud Applications (TOSCA) Primer Version 1.0"/>
            </li>
        </ul>
    </Typography></Stack></Container></Box>)
}
