export type Feature = { title: string, text: string }

export const features: Feature[] = [{
    title: 'Service Models',
    text: 'Next to IaaS, PaaS and SaaS, we support any other model such as CaaS or FaaS. Even at the same time!'
}, {
    title: 'Cloud Models',
    text: 'Public, community, private, or even hybrid cloud? We support them all. At the same time!'
}, {
    title: 'Cloud Providers',
    text: 'There is no restriction! We support any cloud provider.'
}, {
    title: 'TOSCA Conform',
    text: 'Currently we support the deployment of TOSCA 1.0 and modeling of TOSCA Simple Profile 1.3.'
}, {
    title: 'Interfaces',
    text: 'There is a GUI that you can use to model and deploy your applications. In addition, there is a REST and SOAP API can be used to integrate OpenTOSCA into your CI/ CD pipelines.'
}, {
    title: 'Implementation Artifact Types',
    text: 'We support a wide range of implementation artifact types including Script Artifacts (such as Bash or Ruby), Python Archives, Python Scripts, Java Web Applications as well as Ansible playbooks and Chef cookbooks!'
}, {
    title: 'Declarative Deployment',
    text: 'You simply model your application graphically. We take care of the rest.'
}, {
    title: 'Imperative Deployment',
    text: 'Provide management plans which describe each step that we should execute.'
}, {
    title: 'Multi-Cloud Deployment',
    text: 'Your application must de deployed on several clouds at the same time? No problem.'
},  {
    title: 'Continuous Declarative Deployment',
    text: 'Release and deploy a new version of your application. We will generate adaptation plans for you!'
}, {
    title: 'Decentralized Deployment',
    text: 'Deploy your applications even behind firewalls using choreographies or distributed managers.'
},{
    title: 'Declarative Graphical Modeling',
    text: 'Use Winery as a graphical modeling tool for your application!'
}, {
    title: 'Imperative Graphical Modeling',
    text: 'Use Bowie as a graphical modeling tool for your management plans!'
}, {
    title: 'Operating Systems',
    text: 'We support Windows, Linux, and macOS. Take a look at OpenTOSCA and Docker below!'
}
]
