import React from 'react'
import {Box, Container, Stack, Typography} from '@mui/material'
import ExternalButton from '../utils/ExternalButton';

export default function OpenTOSCAVintner() {

    return (
        <Box>
            <Container maxWidth="lg" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>
                    <Typography variant="h4" align="center">
                        OpenTOSCA Vintner
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        OpenTOSCA Vintner is a TOSCA preprocessing and management layer which is able to deploy applications based on TOSCA orchestrator plugins.
                        Preprocessing includes, e.g., the modeling of different deployment variants inside a single deployment model.
                    </Typography>

                    <Stack direction="row" spacing={2} justifyContent="center">
                        <ExternalButton
                            href="https://github.com/OpenTOSCA/opentosca-vintner"
                            text="Repository"
                        />

                        <ExternalButton
                            href="https://vintner.opentosca.org"
                            text="Documentation"/>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}
