import Home from '../components/Home'
import PageNotFoundError from '../components/Error'


type Route = {
    name: string
    path: string
    RouteBody: () => JSX.Element
    getPath?: any
    hidden?: boolean
}

export const routes = {
    home: {
        name: 'Home',
        path: '/',
        RouteBody: Home,
    },

    winery: {
        name: 'Winery',
        path: '/winery',
        RouteBody: Home,
    },

    container: {
        name: 'Container',
        path: '/container',
        RouteBody: Home,
    },

    vintner: {
        name: 'Vintner',
        path: '/vintner',
        RouteBody: Home,
    },

    features: {
        name: 'Features',
        path: '/features',
        RouteBody: Home
    },

    docker: {
        name: 'Docker Compose',
        path: '/docker-compose',
        RouteBody: Home
    },

    resources: {
        name: 'Resources',
        path: '/resources',
        RouteBody: Home,
    },

    openSource: {
        name: 'Open Source',
        path: '/open-source',
        RouteBody: Home,
    },

    projects: {
        name: 'Projects',
        path: '/projects',
        RouteBody: Home,
    },

    publications: {
        name: 'Publications',
        path: '/publications',
        RouteBody: Home,
    },


    aboutUs: {
        name: 'About Us',
        path: '/about',
        RouteBody: Home,
    },

    notFound: {
        name: 'Not Found',
        path: '*',
        RouteBody: PageNotFoundError,
        hidden: true,
    },
}

export const routesArray: Route[] = Object.values(routes)

export default routes
