import React from 'react'
import {Box, Container, Grid, Stack, Typography} from '@mui/material'
import ExternalButton from '../utils/ExternalButton';


export default function EclipseWinery() {

    return (
        <Box>
            <Container maxWidth="lg" sx={{pt: 8, pb: 8}}>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item key="content" sm={12} md={6}>
                        <Stack spacing={4}>

                            <Typography variant="h4" align="center">
                                Eclipse Winery
                            </Typography>

                            <Typography variant="h6" align="center" color="text.secondary" paragraph>
                                Eclipse Winery is a web-based environment to graphically model TOSCA topologies
                                and plans managing these topologies. The environment includes a type and
                                template management component to offer creation and modification of all elements
                                defined in the TOSCA specification. All information is stored in a repository,
                                which allows importing and exporting using the TOSCA packaging format.
                            </Typography>

                            <Stack direction="row" spacing={2} justifyContent="center">
                                <ExternalButton
                                    href="https://github.com/eclipse/winery/"
                                    text="Repository"
                                />

                                <ExternalButton href="https://winery.readthedocs.io/en/latest/"
                                                text="Documentation"/>

                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item key="video" sm={12} md={6}>
                        <iframe style={{border: 0, width: '100%', height: '100%'}}
                                src="https://www.youtube-nocookie.com/embed/xEjarBWcdK0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                    </Grid>
                </Grid>
            </Container>
        </Box>

    )
}
