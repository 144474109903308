import React, { useState } from 'react'
import { routes, routesArray } from './utils/routes'
import { Link, Route, Routes } from 'react-router-dom'
import { AppBar, Box, Button, IconButton, ListItemButton, Toolbar, Typography } from '@mui/material'
import { GitHub, Menu } from '@mui/icons-material'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Layout() {
    const routeComponents = routesArray.map(({ RouteBody, path }) => (
        <Route path={path} key={path} element={<RouteBody />} />
    ))

    const isDesktop = useMediaQuery('(min-width:1000px)');

    const [drawer, setDrawer] = useState(false)
    const toggleDrawer = () => setDrawer(!drawer)

    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} elevation={0}>
                <Toolbar variant="dense">
                    <div hidden={isDesktop}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer}
                        >
                            <Menu />
                        </IconButton>
                    </div>

                    <Typography sx={{ flexGrow: 1 }}>
                        <Button color="inherit" component={Link} to={routes.home.path}>
                            OpenTOSCA
                        </Button>
                    </Typography>

                    <div hidden={!isDesktop}>
                        {routesArray
                            .filter(route => !route.hidden)
                            .map(route => (
                                <Button color="inherit" component={Link} to={route.path} key={route.path}>
                                    {route.name}
                                </Button>
                            ))}

                        <IconButton
                            color="inherit"
                            href="https://github.com/OpenTOSCA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <GitHub />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                open={drawer}
                onClose={toggleDrawer}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer}
                    onKeyDown={toggleDrawer}
                >
                    <Toolbar variant="dense" />
                    <List>
                        {routesArray
                            .filter(route => !route.hidden)
                            .map(route => (
                                <ListItemButton component={Link} key={route.path} to={route.path}>
                                    <ListItemText primary={route.name} />
                                </ListItemButton>
                            ))}

                        <ListItemButton component={Button}
                            href="https://github.com/OpenTOSCA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ListItemText primary="GitHub" />
                        </ListItemButton>
                    </List>
                </Box>

            </Drawer>

            <Toolbar variant="dense" />
            <Routes>{routeComponents}</Routes>
        </>
    )
}
