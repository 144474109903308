import React from 'react';
import {Box, Card, CardActionArea, CardMedia} from '@mui/material';
import ExternalLink from './ExternalLink';

export type LogoCardContent = { key: string, website: string, ext: string }

export default function LogoCard({project}: { project: LogoCardContent }) {
    const dimension = 150
    return <ExternalLink href={project.website}>
        <Card sx={{width: dimension, height: dimension}} variant="outlined">
            <CardActionArea sx={{height: '100%'}}>
                <Box sx={{padding: '16px'}}>
                    <CardMedia
                        component="img"
                        alt={project.key}
                        width={dimension}
                        image={
                            process.env.PUBLIC_URL +
                            '/img/projects/' +
                            project.key +
                            '.' +
                            project.ext
                        }
                    />
                </Box>
            </CardActionArea>
        </Card>
    </ExternalLink>
}
