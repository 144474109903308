import { LogoCardContent } from '../components/utils/LogoCard';

export const fundings: LogoCardContent[] = [
    {
        key: 'bmwk',
        website: 'https://bmwk.de/en',
        ext: 'svg',
    },
    {
        key: 'dfg',
        website: 'https://www.dfg.de/en',
        ext: 'jpg',
    }
];
