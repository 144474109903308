import React from 'react'
import {Box, Container, Grid, Stack, Typography} from '@mui/material'
import {theme} from '../../utils/theme';
import {projects} from '../../repository/projects';
import LogoCard from '../utils/LogoCard';
import {fundings} from '../../repository/funding';

export default function Projects() {

    return (
        <Box sx={{bgcolor: theme.palette.grey[100]}}>
            <Container maxWidth="xl" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4}>
                    <Typography variant="h4" align="center">
                        Projects
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        The development of OpenTOSCA is partially funded by the following projects and the
                        german government.
                    </Typography>

                    <Grid container spacing={2} justifyContent="center" sx={{pt: 4}}>
                        {projects.map(project => (
                            <Grid item key={project.key}>
                                <LogoCard project={project}/>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container spacing={2} justifyContent="center" sx={{pt: 4}}>
                        {fundings.map(funding => (
                            <Grid item key={funding.key}>
                                <LogoCard project={funding}/>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Container>
        </Box>
    )
}
