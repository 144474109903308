import React from 'react'
import {Box, Container, Stack, Typography} from '@mui/material'
import ExternalLink from '../utils/ExternalLink';
import ExternalButton from '../utils/ExternalButton';


export default function Docker() {

    return (
        <Box>
            <Container maxWidth="md" sx={{pt: 8, pb: 8}}>
                <Stack spacing={4} sx={{textAlign: 'center'}}>
                    <ExternalLink href="https://github.com/OpenTOSCA/opentosca-docker">
                        <img
                            alt="docker-compose"
                            width="150"
                            src={
                                process.env.PUBLIC_URL + '/img/docker-compose.webp'
                            }
                        />
                    </ExternalLink>

                    <Typography variant="h4" align="center">
                        Docker Compose
                    </Typography>

                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                        We provide a docker compose file for running the entire OpenTOSCA Ecosystem.
                        <br/>
                        Getting started is now easier than ever!
                    </Typography>

                    <Stack direction="row" spacing={2} justifyContent="center">
                        <ExternalButton href="https://github.com/OpenTOSCA/opentosca-docker" text="Try it!"/>
                    </Stack>

                </Stack>
            </Container>
        </Box>
    )
}
