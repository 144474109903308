import React from 'react'
import './App.css'
import {BrowserRouter} from 'react-router-dom'
import Layout from './Layout'
import {ThemeProvider} from '@mui/material'
import {theme} from './utils/theme'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Layout/>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
