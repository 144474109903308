import {useEffect, useState} from 'react';

export default function usePublications() {
    const [data, setData] = useState<string | null>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        fetch(process.env.PUBLIC_URL + '/dangerously/publications.html')
            .then(async response => {
                setData(await response.text())
            }).catch(e => {
            console.log('Error when fetching publications', e)
            setError(e)
        }).finally(() => setLoading(false))
    }, []);

    return {data, error, loading};
}
